import { post } from 'utils/api/client';

const baseURL = '/api/v1/setting';

class SettingRepository {
    getLanguages(lang: string) {
        // return get<Record<string, string>>(`${baseURL}/languages/${lang}`);
        return {
            USER_INFO: '유저 정보',
            USER_INVENTORY: '인벤토리',
            USER_SANCTIONS: '제재',
            USER_FRIEND: '친구',
            USER_NICKNAME: '닉네임',
            USER_ACCOUNT_INTERLOCK: '계정연동',
            USER_CREATED_AT: '생성일',
            USER_LAST_LOGIN: '마지막 접속일',
            USER_SANCTIONS_STATUS: '제재상태',
            USER_LOGIN_TOTAL_COUNT: '누적 로그인 수',
            USER_LOGIN_CONTIUOUS_COUNT: '연속 로그인 수',
            CHANGE: '변경',
            USER_SANCTIONS_TEMPLATE: '제재 템플릿',
            USER_SANCTIONS_TEMPLATE_CREATE: '템플릿 작성',
            USER_SANCTIONS_WRITE: '제재 작성',
            USER_SANCTIONS_DATE: '기간 제재(일)',
            USER_SANCTIONS_REASON: '제재 사유',
            USER_SANCTIONS_TYPE: '제재 타입',
            COMPLETE: '완료',
            CONFIRM: '확인',
            DETAIL: '상세',
            COPY: '복사',
            DEPLOY: '배포',
            USER_SANCTIONS_CURRENT_SITUATION: '제재 현황',
            USER_SANCTIONS_END_DATE: '제재 종료일',
            RELEASE: '해제',
            CREATE: '생성',
            DELETE: '삭제',
            MAIL_SEND_CONFIRM: '메일 전송 확인',
            CREATED_AT: '생성 날짜',
            EVENT_TITLE: '이벤트 제목',
            MAIL_TITLE: '우편 제목',
            TITLE: '제목',
            MAIL_TYPE: '우편 타입',
            TARGET_USER: '발송 대상',
            STATUS: '상태',
            SEND_DATE: '전송 된 날짜',
            ALL: '전체',
            MAIL_SEVERAL_USER_SET: '여러 유저 한번에 세팅',
            MAIL_EXPIRED_DATE: '수령 후 만료일',
            THREE_DAY: '3일',
            FIVE_DAY: '5일',
            MAIL_ITEM: '메일 첨부 아이템',
            PERSON: '담당자',
            CANCEL: '취소',
            SAVE: '저장',
            ALL_USER: '전체',
            PERSON_COUNT: '명',
            MAIL_PREVIEW: '우편 미리보기',
            SEND_PERSON: '발송인',
            CONTENT: '내용',
            ITEM: '아이템',
            SUBSCRIBE_ITEM: '기간 아이템',
            SEND_PERIOD: '전송 기간',
            EXPOSURE_PERIOD: '노출 기간',
            TYPE: '타입',
            LANGUAGE: '언어',
            PUBLISH: '배포',
            EDIT: '수정',
            STOP: '중지',
            INNER_OPEN: '내부 오픈',
            OPEN: '오픈',
            START_AT: '시작 날짜',
            END_AT: '종료 날짜',
            PLEASE_INPUT_KEYWORD: '키워드를 입력해주세요.',
            SEARCH: '검색',
            CONFIRM_TITLE: '정말로 반영하시겠습니까?',
            CONFIRM_SANCTIONS: '정말로 제재하시겠습니까?',
            CONFIRM_INNER_OPEN: '정말 내부 오픈하시겠습니까?',
            CONFIRM_INNER_OPEN_CONTENT: '해당 공지를 정말 내부 오픈하시겠습니까?',
            CONFIRM_STOP: '정말 중지하시겠습니까?',
            CONFIRM_NOTICE_STOP_CONTENT: '해당 공지를 정말 중지 하시겠습니까?',
            CONFIRM_REPAYMENT: '재결제 요청 하시겠습니까?',
            NOTICE: '공지사항',
            NOTICE_URGENT: '긴급 공지',
            NOTICE_NORMAL: '일반 공지',
            NOTICE_INGAME: '인게임 공지',
            REGISTRATION: '등록',
            NOTICE_CREATE: '공지 작성',
            NOTICE_REGIST: '공지 등록',
            NOTICE_FIRST_LANGUAGE: '우선 언어 선택',
            NOTICE_FIRST_LANGUAGE_MESSAGE: '우선으로 적용될 언어를 선택해주세요.',
            NEXT: '다음',
            NOTICE_CONFIRM: '위 내용으로 정말 등록하시겠습니까? 등록 후 배포해야 게임에 반영됩니다.',
            NOTICE_CONFIRM_WARNING: '바로 배포를 누를 경우 취소를 할 수 없으니 주의하세요.',
            INGAME_NOTICE_CONFIRM_WARNING:
                '노출 시작 시간이 지난 공지사항은 바로 게임서버로 배포 됩니다. 그렇지 않은 경우 예약상태로 변경됩니다.',
            BACK: '뒤로가기',
            ITEM_VIEW: '아이템 조회',
            ITEM_NUMBER: '아이템 번호',
            ITEM_NAME: '아이템 이름',
            ITEM_ID: '아이템 아이디',
            HEADER_HELLO: '{name}님',
            MANAGEMENT: '관리',
            LOGOUT: '로그아웃',
            MYPAGE: '내정보',
            ID: '아이디',
            NICK_NAME: '별명',
            PASSWORD: '비밀번호',
            REGIST_DATE: '가입날짜',
            PRE_PASSWORD: '이전 비밀번호',
            NEW_PASSWORD: '새로운 비밀번호',
            CONFIRM_PASSWORD: '비밀번호 확인',
            MY_ROLE: '내 역할',
            GAME_MANAGE_TOOL_LOGIN: '게임 운영 툴 로그인',
            DID_YOU_MANGER: '111% 직원이신가요?',
            PLEASE_INPUT_PASSWORD: '비밀번호를 입력해주세요.',
            PLEASE_INPUT_CONFIRM: '한번 더 입력해주세요.',
            PASSWORD_CONFIRM_FAIL: '새로운 비밀번호와 확인은 동일해야합니다.',
            TABLE_ID_REQUIRED: '테이블 아이디는 필수 입니다.',
            TABLE_ID_NUMBER: '테이블 아이디는 number 타입입니다.',
            ITEM_OVER_SEND: '지급 갯수는 최대 갯수를 초과할 수 없습니다.',
            TITLE_REQUIRED: '제목은 필수 입니다.',
            USER_ID_REQUIRED: '유저 아이디는 필수 입력입니다.',
            SEND_TIME_REQUIRED: '발송 시작은 필수 입니다.',
            END_TIME_REQUIRED: '발송 종료는 필수 입니다.',
            START_MORE_THEN_END: '발송 종료 시간은 발송 시작 시간보다 뒤에 있어야 합니다.',
            TITLE_SIZE: '타이틀은 {min} ~ {max} 자 사이로 적어주세요.',
            CONTENT_MAX_GT: '내용의 길이가 너무 깁니다\r\n {max}자 이내로 작성을 해주세요.',
            COUNT_IS_NUMBER: '카운트는 숫자로 입력해주세요.',
            MAX_DATE_MESSAGE: '최대 일수는 {max}일 입니다.',
            LOGIN_EXPIRED: '로그인이 만료되었습니다. 로그아웃합니다.',
            PLEASE_WAIT: '잠시 기다려 주세요.',
            NO_HAVE_PERMISSION: '권한이 없습니다.\r\n 관리자에게 문의해주세요.',
            MAIL_SEND_CONFIRM_MESSAGE: '정말로 발송하시겠습니까?\r\n발송할 경우 수정할 수 없습니다.',
            MAIL_SEND_SUCCESS: '발송 요청을 보냈습니다.',
            MAIL_DETAIL: '우편 상세',
            MAIL_SEND: '우편 발송',
            SUCCESS_REGIST: '정상적으로 등록되었습니다.',
            SUCCESS_EDIT: '정상적으로 수정되었습니다.',
            SUCCESS_PUBLISH: '정상적으로 배포되었습니다.',
            CONFIRM_NOTICE_TITLE_TEMPLATE: '정말 {message}하시겠습니까?',
            CONFIRM_NOTICE_CONTENT_TEMPLATE: '해당 공지를 정말 {message}하시겠습니까?',
            SUCCESS_NOTICE_TEMPLATE: '정상적으로 {message} 되었습니다.',
            PLEASE_INPUT_TITLE: '제목을 입력해주세요.',
            PLEASE_INPUT_VERSION:
                '앱 버전을 설정해주세요. ex: 1.0.0 등 입력 후 엔터 (다중 버전 입력 가능, 해당 기능을 지원하지 않는 게임은 현재 버전을 입력해주세요)',
            PLEASE_SELECT_FIRST_LANGUAGE: '우선적으로 적용될 언어를 선택해주세요.',
            VALIDATION_ERROR: '유효성 검사 오류',
            BLOCK_ACCOUNTS: '계정이 비활성화 상태가 되었습니다.\r\n 관리자에게 문의해주세요.',
            INVAILD_PASSWORD: '비밀번호가 다릅니다. 5회 이상 다를 경우 계정이 비활성화 됩니다.',
            NOT_STAFF_USER: '사내 계정이 아닙니다. 발급받은 계정으로 로그인 해주시기 바랍니다.',
            NO_AUTHORIZATION_MAIL_FOR_EDIT: '메일을 수정할 권한이 없습니다.',
            THE_MAIL_TYPE_IS_NOT_CORRECT: '우편 타입이 일치하지 않습니다.',
            'MAIL_IN_PROGRESS_OR_COMPLETE_CANNOT BE_MODIFIED': '처리중이거나 완료된 우편은 수정할 수 없습니다.',
            YOU_CANNOT_SEND_MAIL_THAT_IS_BEING_PROCESSEDOR_COMPLETED:
                '처리중이거나 처리 완료된 우편은 발송할 수 없습니다.',
            PROJECT_SELECT: '프로젝트 선택',
            SEND_RIGHT_OFF: '발송 즉시',
            ALL_STOP_AND_BACK: '현재 모든 작업을 중지하고 돌아가시겠습니까?',
            ONLY_CHECK_TITLE: '관리자 확인용 제목입니다. 실제 유저에게 전송 되지 않습니다.',
            USERNAME: '이름',
            CONFIRM_DELETE: '정말로 삭제 하시겠습니까?',
            REPEAT_NUM: '반복 횟수',
            NOTICE_INPUT_PLACEHOLDER: '공지 내용을 입력해주세요 (최대 {max}글자)',
            BASE_SERVER_100: '서버가 운영되고있지 않습니다.',
            BASE_SERVER_101: '허용되지 않은 IP로 접근했습니다.',
            BASE_SERVER_102: '아이템을 찾을 수 없습니다.',
            BASE_SERVER_103: 'DB에 저장을 실패했습니다.',
            BASE_SERVER_104: '입력값을 확인해주세요.',
            BASE_SERVER_105: '필요 아이템이 충분하지 않습니다.',
            BASE_SERVER_1000: '계정 정보를 찾을 수 없습니다.',
            BASE_SERVER_1001: '지원하지 않는 플랫폼 타입입니다.',
            BASE_SERVER_1002: '중복 계정 요청이 들어왔습니다.',
            BASE_SERVER_1003: '중복 플랫폼 요청이 들어왔습니다.',
            BASE_SERVER_2000: '중복된 닉네임입니다.',
            BASE_SERVER_2001: '조건에 맞지 않는 닉네임입니다.',
            BASE_SERVER_2002: '이미 닉네임을 설정한 유저입니다.',
            BASE_SERVER_2003: '인증 토큰이 없습니다. 관리자에게 문의해주세요.',
            BASE_SERVER_2004: '중복 리뷰 요청입니다.',
            BASE_SERVER_2005: '잘못된 튜토리얼 스탭 요청입니다.',
            BASE_SERVER_2006: '경험치가 부족합니다.',
            BASE_SERVER_2007: '일일 광고 시청 횟수가 지났습니다.',
            BASE_SERVER_2008: '잘못된 광고 시청 토큰입니다.',
            BASE_SERVER_2009: '해당 클래스 보상 정보를 찾지 못했습니다.',
            BASE_SERVER_2010: 'VIP 유저가 아닙니다.',
            BASE_SERVER_2011: '존재하지 않는 유저입니다.',
            BASE_SERVER_3000: '해당 캐릭터를 찾지 못했습니다.',
            BASE_SERVER_3001: '이미 최대 레벨의 캐릭터입니다.',
            BASE_SERVER_3002: '캐릭터 경험치가 부족합니다.',
            BASE_SERVER_4000: '해당 아이템을 찾지 못했습니다.',
            BASE_SERVER_4001: '아이템 추가에 실패했습니다.',
            BASE_SERVER_4002: '아이템 삭제에 실패했습니다.',
            BASE_SERVER_5000: '우편 정보를 찾지 못했습니다.',
            BASE_SERVER_5001: '해당 우편 정보를 찾지 못했습니다.',
            BASE_SERVER_5002: '우편함이 가득 찼습니다.',
            BASE_SERVER_5003: '만료된 우편입니다.',
            BASE_SERVER_7000: '배틀 정보를 찾을 수 없습니다.',
            BASE_SERVER_7001: '해당 덱을 찾을 수 없습니다.',
            BASE_SERVER_7002: '이미 참여중인 유저입니다.',
            BASE_SERVER_7003: '참여 하지 않는 유저입니다.',
            BASE_SERVER_7004: '이미 보상을 받았습니다.',
            BASE_SERVER_7005: '승리 횟수가 부족합니다.',
            BASE_SERVER_7006: '이미 존재하는 방 아이디 입니다.',
            BASE_SERVER_7007: '클래스가 부족합니다.',
            BASE_SERVER_8000: '해당 퀘스트를 찾지 못했습니다.',
            BASE_SERVER_8001: '완료되지 않은 퀘스트입니다.',
            BASE_SERVER_8002: '일일보상 요청 인덱스가 잘못되었습니다.',
            BASE_SERVER_8003: '이미 일일 보상을 획득했습니다.',
            BASE_SERVER_8004: '퀘스트 초기화를 더 이상 할 수 없습니다.',
            BASE_SERVER_10000: '상점 정보를 찾지 못했습니다.',
            BASE_SERVER_10001: '결제 내역 복구 처리 무시',
            BASE_SERVER_10002: '일일 상점 정보를 찾을 수 없습니다.',
            BASE_SERVER_11000: '친구 목록이 꽉 찼습니다.',
            BASE_SERVER_11001: '상대 유저의 친구목록이 꽉 찼습니다.',
            BASE_SERVER_11002: '선물 시간이 아직 안되었습니다.',
            BASE_SERVER_11003: '이미 친구목록에 있는 사람입니다.',
            BASE_SERVER_11004: '요청한 친구 유저의 정보를 찾을 수 없습니다.',
            BASE_SERVER_11005: '내 정보를 상대방 친구목록에서 찾을 수 없습니다.',
            BASE_SERVER_11006: '해당 유저가 친구 요청 목록에 없습니다.',
            BASE_SERVER_12000: '랭킹을 찾을 수 없습니다.',
            BASE_SERVER_13000: '시즌 정보를 찾을 수 없습니다.',
            BASE_SERVER_13001: '시즌 보상 테이블 데이터를 찾을 수 없습니다.',
            BASE_SERVER_13002: '유효하지 않은 시즌 보상 아이디',
            BASE_SERVER_14000: '공지 사항 정보가 없습니다.',
            BASE_SERVER_100000: '해당 클래스를 클래스 정보 테이블에서 찾을 수 없습니다.',
            BASE_SERVER_101000: '해당 캐릭터 업그레이드 테이블을 찾을 수 없습니다.',
            BASE_SERVER_102000: '해당아이템을 아이템 리스트 테이블에서 찾을 수 없습니다.',
            BASE_SERVER_102001: '해당아이템을 아이템 리소스에서 찾을 수 없습니다.',
            BASE_SERVER_103000: '해당 퀘스트를 퀘스트 리스트 테이블에서 찾을 수 없습니다.',
            BASE_SERVER_103001: '해당 업적을 업적리스트 테이블에서 찾을 수 없습니다.',
            BASE_SERVER_103002: '퀘스트 일일 보상 테이블을 찾을 수 없습니다.',
            BASE_SERVER_103003: '퀘스트 일일 보상 확률이 잘못 되었습니다.',
            BASE_SERVER_104000: '해당 인게임 보상 테이블을 찾을 수 없습니다.',
            BASE_SERVER_104001: '해당 클래스 보정 테이블을 찾을 수 없습니다.',
            BASE_SERVER_104002: '해당 클래스 보상 테이블을 찾을 수 없습니다.',
            BASE_SERVER_105000: '상점정보 테이블을 찾을 수 없습니다.',
            BASE_SERVER_106000: '일일 승리 데이터를 찾을 수 없습니다.',
            BASE_SERVER_106001: '스테이지 보상 데이터를 찾을 수 없습니다.',
            NOT_HAVE_TOKEN: '인증 정보가 없습니다.',
            INVAILD_TOKEN: '잘못된 토큰입니다.',
            SERVER_URL_SET: '서버 URL이 설정 되어있지 않습니다. 관리자에게 문의해주세요.',
            NOTICE_CANNOT_EDIT: '이미 전송한 공지는 수정 할 수 없습니다.',
            NOTICE_CANNOT_SEND: '이미 전송한 공지는 다시 전송 할 수 없습니다.',
            INVAILD_PROJECT: '프로젝트가 존재하지 않습니다.',
            PLEASE_QA_TEST: 'QA에서 이번 업데이트를 검증해주세요.',
            NOT_FOUND_NOTICE_PUBLISH: '현재 게시되었는 긴급 공지가 없습니다.',
            MORE_THEN_TODAY: '현재 날짜보다 이전으로 입력 할 수 없습니다.',
            COMMODITIES: '재화',
            CHARACTER: '캐릭터',
            PLEASE_INPUT_PID: 'PID를 입력해주세요.',
            CONFIRM_ACCOUNT_MERGE: '정말로 계정이전 하시겠습니까?',
            FRIEND: '친구',
            ACCOUNT_INTERLOCK: '계정연동',
            PLEASE_SELECT: '선택해주세요.',
            SENDER: '보낸사람',
            WRITE: '작성',
            AUTHOR: '작성자',
            EDITOR: '수정자',
            REALY: '정말',
            ARE_YOU_DO_IT: '하시겠습니까?',
            TARGET_NOTICE_REALY: '해당 공지를 정말',
            SUCCESSFULY: '정상적으로',
            DO_IT: '되었습니다.',
            USER_ACCOUNT_RESTORE: '유저 계정 이전',
            CURRENT_ACCOUNT: '현재 계정',
            NEW_ACCOUNT: '새로운 계정',
            ACCOUNT_RESTORE: '계정 이전',
            RESTORE_ACCOUNT_CONFIRM: '정말로 이전 하시겠습니까?',
            RESTORE_ACCOUNT_SUCCESSS: '성공적으로 계정이전이 되었습니다.',
            SANCTION_REALESE_CONFIRM: '정말로 제재를 해제하시겠습니까?',
            PLEASE_CURRENT_ACCOUT_CHECK: '현재 계정의 {var}를 확인해주세요.',
            PLEASE_NEW_ACCOUT_CHECK: '새로운 계정의 {var}를 확인해주세요.',
            GAME_TOOL_LOGIN: '게임 운영툴 로그인',
            DID_YOU_111PERCENT: '111% 직원이신가요?',
            SYSTEM_MAIL_TYPE: '전체 유저 메일 타입',
            CHECK_DATA: '타입 체크 데이터',
            NO_SELECT: '선택 안함',
            APP_VERSION: '앱버전',
            EQUAL_CREATE_USER: '지정한 날짜에 생성된 유저',
            LESS_THAN_CREATE_USER: '지정한 날짜보다 전에 생성된 유저',
            GREATE_THAN_CREATE_USER: '지정한 날짜보다 이후에 생성된 유저',
            EQUAL_CLASS_ID: '해당 클래스 ID와 같은 유저',
            LESS_TAN_CLASS_ID: '해당 클래스 ID보다 작은 유저',
            GREATER_THAN_CLASS_ID: '해당 클래스 ID보다 큰 유저',
            CLIPBOARD_COPY_COMPLETE: '클립보드에 복사했습니다 ctrl + v 로 붙여넣어주세요.',
            ERROR_SEARCH_RESULT: '검색 결과 오류가 있습니다. 다시 검색해주세요.',
            PERMANENT_SANCTIONS: '영구 제재',
            GUID: '기기ID',
            GUID_BEN: '기기벤',
            GUID_BEN_ACCOUNT_ID: '기기벤 대상 유저 아이디',
            CURRENT_USER_GUID_BEN_CONTENT: '현재 유저가 사용하는 기기에서 접속 할 수 없도록 합니다.',
            DATA_PENDING: '데이터 받아오는중',
            LOG: '로그',
            PLEASE_SEARCH_ITEM: '아이템을 검색해주세요.',
            DASHBOARD: '대시보드',
            MAIL: '우편함',
            PROMOTION: '프로모션',
            PROJECT: '프로젝트',
            ACCOUNT_MERGE: '계정 이전',
            SEND_URL: '첨부URL',
            OPEN_IT_AND_SEE: '펼쳐서 보기',
            SEASON_INFO: '시즌패스정보',
            SEASON: '시즌',
            QUEST: '퀘스트',
            ATTENDANCE: '출석',
            PASS_SEASON: '패스 시즌',
            ACTIVATION: '활성화',
            DEACTIVATE: '비활성화',
            HAS_SEASON_PASS: '시즌 패스 활성화 여부',
            SEASON_POINT: '시즌 포인트',
            PASS: '패스',
            REQUEST_SUCCESS: '요청이 정상적으로 적용 되었습니다.',
            RECEIVE: '수령',
            NOT_RECEIVE: '미수령',
            RECEIVE_CURRENT: '수령 현황',
            CLASS_PASS: '클래스 패스',
            DAY: '일차',
            HAS_REWARD: '수령 여부',
            ATTENDANCE_CHANGE_MESSAGE: '출석 상태를 이 날짜로 합니다.',
            ATTENDANCE_ID: '출석부 ID',
            ATTENDANCE_RANGE: '출석부 기간',
            ATTENDANCE_LAST_TIME: '마지막 출석부 보상 시간',
            NORMAL_PASS: '일반 패스',
            PREMIUM_PASS: '프리미엄 패스',
            MAX_HAVE_TO_SEND: '최대 {var}명 까지 전송 할 수 있습니다.',
            PURCHASE_LOGS: '결제 로그',
            DATA_FETCHING: '데이터를 가져오고 있습니다.',
            USER_PURCHASE_NAV_TOOLTIP:
                '검색 기준 날짜가 UTC 이므로 검색하고자 하는 날짜 범위를 하루씩 늘려서 검색하는 것을 권장합니다.',
            DOWNLOAD_CAN_AFTER_SEARCH:
                '다운로드 기능은 현재 검색 되어있는 조건으로 검색합니다. 먼저 검색을 하시고 다운로드 해주시기 바랍니다.',
            SUCCESS_UNPUBLISH: '정상적으로 배포 중지했습니다.',
            UNPUBLISH: '배포 중지',
            NOTICE_UNPUBLISH_CONFIRM: '해당 공지를 정말로 중지 하시겠습니까?',
            VOIDED_PURCHASE: '환불내역',
            ORDER: '결제내역',
            BLOCK_USER: '유저 단체 제재',
            CONTENTS: '콘텐츠 관리',
            USER: '유저 관리',
            REPORT: '신고 관리',
            MAINTENANCE: '점검 관리',
            MARKETING: '마케팅 관리',
            POLICY: '정책 관리',
            COMMON_MANAGEMENT: '공통 관리',
            SITE_MANAGEMENT: '사이트 관리',
            SETTING: '설정',
            APP_ADS: 'App-ads 관리',
            DEVELOPMENT: '개발 관리',
            REFUND: '환불',
            REPAYMENT: '재결제',
            REPAYMENT_REQUEST: '재결제 요청',
            REPAYMENT_PRODUCT: '재결제 상품',
            REPAYMENT_LOG: '재결제 요청 로그',
            CANCEL_RESERVATION: '예약 취소',
            SUCCESS_RANKING_DATA_CREATE: '랭킹 데이터가 추가되었습니다.',
            FAIL_RANKING_DATA_CREATE: '랭킹데이터 추가 실패',
            SUCCESS_RANKING_DATA_EDIT: '랭킹 데이터가 수정되었습니다.',
            SUCCESS_RANKING_DATA_DELETE: '랭킹 데이터가 삭제되었습니다.',
            SUCCESS_RANKING_DATA_CLEAR: '랭킹 데이터가 초기화되었습니다.',
            PLEASE_ENTER_CORRECT_JSON_FORMAT: '올바른 JSON 형태를 입력해주세요.',
            SUCCESS_PROJECT_CREATE: '프로젝트를 생성했습니다.',
            FAIL_PROJECT_CREATE: '프로젝트 생성 실패',
            SUCCESS_PROJECT_EDIT: '프로젝트를 수정했습니다.',
            FAIL_PROJECT_EDIT: '프로젝트 수정 실패',
            SUCCESS_PROJECT_DELETE: '프로젝트를 삭제했습니다.',
            FAIL_PROJECT_DELETE: '프로젝트 삭제 실패',
            SUCCESS_REPLY_SUBMIT: '답변이 등록되었습니다.',
            SUCCESS_CREATE: '정상적으로 생성되었습니다.',
            FAIL_CREATE: '생성 실패',
            FAIL_EDIT: '수정 실패',
            SUCCESS_DELETE: '정상적으로 삭제되었습니다.',
            FAIL_DELETE: '삭제 실패',
            SUCCESS_POLICY_CREATE: '정책이 추가되었습니다.',
            SUCCESS_UPLOAD: '정상적으로 업로드되었습니다.',
            FAIL_UPLOAD: '업로드 실패',
            FAIL_PUBLISH: '배포 실패',
            SUCCESS_REFLECT: '정상적으로 반영되었습니다.',
            SUCCESS_USER_NAME_EDIT: '유저 이름을 변경했습니다.',
            SUCCESS_PASSWORD_EDIT: '비밀번호를 변경했습니다.',
            SUCCESS_TEMPLATE_CREATE: '템플릿이 등록되었습니다.',
            FAIL_TEMPLATE_CREATE: '템플릿 등록 실패',
            SUCCESS_TEMPLATE_EDIT: '템플릿이 수정되었습니다.',
            FAIL_TEMPLATE_EDIT: '템플릿 수정 실패',
            SUCCESS_CHANNEL_EDIT: '채널이 수정되었습니다.',
            FAIL_CHANNEL_EDIT: '채널 수정 실패',
            SUCCESS_DEFAULT_POLICY_EDIT: '기본 정책이 수정되었습니다.',
            FAIL_DEFAULT_POLICY_EDIT: '기본 정책 수정 실패',
            SUCCESS_DM_POLICY_EDIT: 'DM 정책이 수정되었습니다.',
            FAIL_DM_POLICY_EDIT: 'DM 정책 수정 실패',
            SUCCESS_CHANNEL_POLICY_CREATE_OR_EDIT: '채널 정책이 생성/수정되었습니다.',
            FAIL_CHANNEL_POLICY_CREATE_OR_EDIT: '채널 정책 생성/수정 실패',
            SUCCESS_CHANNEL_DELETE: '채널이 삭제되었습니다.',
            FAIL_CHANNEL_DELETE: '채널 삭제 실패',
            SUCCESS_MESSAGE_SEND: '메시지를 전송했습니다.',
            FAIL_MESSAGE_SEND: '메시지 전송 실패',
            SUCCESS_COMMUNITY_DATA_CREATE: '커뮤니티 데이터가 추가되었습니다.',
            SUCCESS_COMMUNITY_DATA_DELETE: '커뮤니티 데이터가 삭제되었습니다.',
            SUCCESS_GUILD_NAME_EDIT: '길드명이 변경되었습니다.',
            FAIL_GUILD_NAME_EDIT: '길드명 변경 실패',
            SUCCESS_GUILD_WITHDRAW: '길드가 해체되었습니다.',
            FAIL_GUILD_WITHDRAW: '길드 해체 실패',
            SUCCESS_DUMMY_DATA_CREATE: '더미데이터가 생성되었습니다.',
            DELETE_DUMMY_DATA_CREATE: '더미데이터가 삭제되었습니다.',
            FAIL: '실패',
            SUCCESS_POLICY_EDIT: '정책이 수정되었습니다.',
            SUCCESS_LANGUAGE_CREATE: '언어가 추가되었습니다.',
            SUCCESS_POLICY_DATA_CREATE: '정책 데이터가 생성되었습니다.',
            SUCCESS_POLICY_DATA_EDIT: '정책 데이터가 수정되었습니다.',
            SUCCESS_JSON_DATA_DEPLOY: 'JSON 데이터가 배포되었습니다.',
            CLASS: '클래스',
            SUCCESS_ROLE_CREATE: '역할이 생성되었습니다.',
            FAIL_ROLE_CREATE: '역할 생성 실패',
            SUCCESS_ROLE_EDIT: '역할이 수정되었습니다.',
            FAIL_ROLE_EDIT: '역할 수정 실패',
            SUCCESS_ROLE_DELETE: '역할이 삭제되었습니다.',
            FAIL_ROLE_DELETE: '역할 삭제 실패',
            SUCCESS_VALIDATION_EDIT: '미등록 조건이 수정되었습니다.',
            SUCCESS_RANKING_DATA_RESET: '랭킹 데이터가 초기화되었습니다.',
            SUCCESS_ITEM_PACKAGE_CREATE: '아이템 패키지가 생성되었습니다.',
            SUCCESS_ITEM_PACKAGE_DELETE: '아이템 패키지가 삭제되었습니다.',
            SUCCESS_ITEM_PACKAGE_EDIT: '아이템 패키지가 수정되었습니다.',
            SUCCESS_MENU_CREATE: '메뉴를 추가했습니다.',
            SUCCESS_MENU_DELETE: '메뉴를 삭제했습니다.',
            SUCCESS_WITHDRAW_CANCEL: '탈퇴 처리를 취소했습니다.',
            PLEASE_CHECK_INPUT: '입력을 확인해주세요.',
            SUCCESS_PUSH_CREATE: '푸시가 생성되었습니다.',
            SUCCESS_BUILD_ENVIRONMENT_EDIT: '빌드 환경이 수정되었습니다.',
            SUCCESS_VIDEO_DATA_CREATE: '영상 데이터가 추가되었습니다.',
            SUCCESS_VIDEO_DATA_EDIT: '영상 데이터가 수정되었습니다.',
            SUCCESS_VIDEO_DATA_DELETE: '영상 데이터가 삭제되었습니다.',
            SUCCESS_USER_INFO_IMPORT: '유저 정보가 삽입되었습니다.',
            FAIL_USER_INFO_IMPORT: '유저 정보 삽입 실패',
            SUCCESS_COORDINATE_SAVE: '좌표를 저장했습니다.',
            SUCCESS_APPLY: '성공적으로 적용되었습니다.',
            SUCCESS_USER_EXCLUDED: '유저가 제외되었습니다.',
            SUCCESS_SCORE_EDIT: '스코어가 수정되었습니다.',
            SUCCESS_RESERVATION_CANCEL: '예약이 취소되었습니다.',
            SUCCESS_APPLICANT_CANCEL: '가입 요청이 취소되었습니다.',
            FAIL_APPLICANT_CANCEL: '가입 요청 실패',
            SUCCESS_GUILD_MASTER_EDIT: '길드 마스터가 변경되었습니다.',
            FAIL_GUILD_MASTER_EDIT: '길드 마스터 변경 실패',
            SUCCESS_REBAN: '다시 제재되었습니다.',
            FAIL_REBAN: '다시 제재 실패',
            SUCCESS_BAN: '제재 되었습니다.',
            FAIL_BAN: '제재 실패',
            SUCCESS_BAN_DEVICE: '기기가 제한되었습니다.',
            FAIL_BAN_DEVICE: '기기 제한 실패',
            SUCCESS_UNBAN: '제재가 해제되었습니다.',
            FAIL_UNBAN: '제재 해제 실패',
            SUCCESS_UNBAN_DEVICE: '기기 제한이 해제되었습니다.',
            FAIL_UNBAN_DEVICE: '기기 제한 해제 실패',
            SUCCESS_MEMBER_WITHDRAW: '멤버를 추방하였습니다.',
            FAIL_MEMBER_WITHDRAW: '멤버 추방 실패',
        };
    }

    uploadFile(formData: FormData) {
        return post<string>(`${baseURL}/upload`, formData);
    }
}

export default new SettingRepository();
