import { Button, Spin, Space, Form, Input } from 'antd';
import { EnterIgnoreForm, OddFillTable, ProjectModal } from 'components';
import { useModal } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectCreateRequest, ProjectDetailDto, ProjectUpdateRequest } from '@gamebase-web-ops/project';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { css } from '@emotion/react';
import { HeaderDesc } from 'components/utils/Header';
import ProjectDetail from './ProjectDetail';
import useProjectList from 'hooks/admin/useProjectList';
import { COMMON_PROJECT_ID } from 'constants/constants';
import { Common } from 'styles/common';
import { MyProjectDto } from '@gamebase-web-ops/authorization/lib/v2';
import { useParams } from 'react-router-dom';

interface ProjectListProps {
    isProjectMenuList: boolean;
}

export enum BUTTON_TYPE {
    CREATE,
    DETAIL,
    EDIT,
}

const ProjectList: React.FC<ProjectListProps> = ({ isProjectMenuList }) => {
    const {
        myProjectList,
        isProjectListError,
        isProjectListLoading,
        projectDetail,
        setProjectDetail,
        createProject,
        updateProject,
        deleteProject,
    } = useProjectList();
    const [clickMode, setClickType] = useState<BUTTON_TYPE>(BUTTON_TYPE.CREATE);
    const { visible, openModal, closeModal } = useModal();
    const onCreateProjectSubmit = useCallback(
        async (arg: ProjectCreateRequest | ProjectUpdateRequest) => {
            if (projectDetail) {
                await updateProject(arg as ProjectUpdateRequest);
                closeModal();
                setProjectDetail(null);
                // await withScreenLockCall(putProjectRequest, arg as ProjectUpdateRequest);
            } else {
                await createProject(arg as ProjectCreateRequest);
                closeModal();
                setProjectDetail(null);
                //  await withScreenLockCall(createProjectRequest, arg as ProjectCreateRequest);
            }
            closeModal();
            setProjectDetail(null);
        },
        [projectDetail, closeModal, setProjectDetail, updateProject, createProject],
    );
    const [searchWord, setSearchWord] = useState('');

    const closeProjectModal = useCallback(() => {
        closeModal();
        setProjectDetail(null);
    }, [closeModal, setProjectDetail]);

    const { id = '' } = useParams<{ id?: string }>();

    const deleteClickListener = useCallback(
        async (id: string) => {
            if (window.confirm('프로젝트를 정말 삭제하시겠습니까?')) {
                await deleteProject({ id, projectId: COMMON_PROJECT_ID });
                // await withScreenLockCall(deleteProjectRequest, id);
            }
        },
        [deleteProject],
    );

    const projectTableColumns = useMemo<ColumnsType<ProjectDetailDto>>(
        () => [
            {
                key: 'name',
                dataIndex: 'name',
                title: '프로젝트명',
                render(name: string) {
                    const matcher = new RegExp(`(${searchWord})`, 'gi');
                    const matches = name.split(searchWord.trim() ? matcher : ' ');
                    return matches.map((it, index) => {
                        if (it.match(new RegExp(`^${searchWord}$`, 'gi'))) {
                            return (
                                <mark key={index} css={highlight}>
                                    {it}
                                </mark>
                            );
                        } else {
                            return <span key={index}>{it}</span>;
                        }
                    });
                },
            },
            {
                key: 'gameId',
                dataIndex: 'globalId',
                title: '게임 아이디',
                render(gameId) {
                    if (String(gameId) === searchWord) {
                        return <mark css={highlight}>{gameId}</mark>;
                    } else {
                        return gameId;
                    }
                },
                sortDirections: ['descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => {
                    return Number(a.globalId) - Number(b.globalId);
                },
            },
            {
                key: 'createdAt',
                dataIndex: 'createdAt',
                title: '생성일',
                render: (createdAt, record) => {
                    return `${moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}(${record.createdBy})`;
                },
            },
            {
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                title: '수정일',
                render: (updatedAt, record) => {
                    return `${moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}(${record.updatedBy})`;
                },
            },
            {
                key: 'management',
                dataIndex: 'management',
                title: '관리',
                render(_, record) {
                    return (
                        <div css={managementBtnWrapper}>
                            <Button
                                onClick={async () => {
                                    setClickType(BUTTON_TYPE.DETAIL);
                                    await setProjectDetail(record);
                                    openModal();
                                }}
                            >
                                상세
                            </Button>
                            <Button
                                onClick={() => {
                                    setClickType(BUTTON_TYPE.EDIT);
                                    setProjectDetail(record);
                                    openModal();
                                }}
                            >
                                수정
                            </Button>
                            <Button
                                type="primary"
                                danger
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    deleteClickListener(record.id);
                                }}
                            >
                                삭제
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [deleteClickListener, openModal, setProjectDetail, searchWord],
    );

    useEffect(() => {
        if (id && myProjectList) {
            const project = myProjectList.find((it) => it.id === id);

            setProjectDetail((project ?? null) as ProjectDetailDto | null);
            openModal();
        }
    }, [id, myProjectList, setProjectDetail, openModal]);

    return (
        <div>
            {visible &&
                (clickMode === BUTTON_TYPE.DETAIL ? (
                    <ProjectDetail
                        visible={visible}
                        title="프로젝트 상세"
                        onCancel={closeProjectModal}
                        projectValues={projectDetail}
                    />
                ) : (
                    <ProjectModal
                        isProjectMenuList={isProjectMenuList}
                        visible={visible}
                        onCancel={closeProjectModal}
                        title={projectDetail ? '프로젝트 수정' : '프로젝트 생성'}
                        onSubmit={onCreateProjectSubmit}
                        projectValues={projectDetail}
                        mode={clickMode}
                    />
                ))}

            {!isProjectMenuList && (
                <div css={buttonWrapper}>
                    <HeaderDesc desc="프로젝트를 생성하고 콘텐츠 권한을 설정할 수 있습니다." />
                    <Button
                        onClick={() => {
                            setClickType(BUTTON_TYPE.CREATE);
                            openModal();
                        }}
                        type="primary"
                    >
                        생성
                    </Button>
                </div>
            )}
            <Spin spinning={isProjectListLoading} tip="로딩중..">
                {isProjectListError ? (
                    <div>권한이 없습니다.</div>
                ) : (
                    <>
                        <Space css={searchWrapper} size="middle">
                            <EnterIgnoreForm
                                css={inputWrapper}
                                enabledenter
                                onSubmit={(args: any) => {
                                    setSearchWord(args.searchWord);
                                }}
                            >
                                <Form.Item style={{ width: 300 }}>
                                    <Input
                                        size="middle"
                                        name="searchWord"
                                        placeholder="프로젝트 명, 게임 아이디 검색"
                                        onBlur={(e) => {
                                            setSearchWord(e.target.value);
                                        }}
                                    ></Input>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" size="middle">
                                        검색
                                    </Button>
                                </Form.Item>
                            </EnterIgnoreForm>
                        </Space>
                        <OddFillTable
                            pagination={{ position: ['bottomCenter'] }}
                            dataSource={myProjectList
                                ?.map((project: MyProjectDto) => ({ ...project, key: project.id }))
                                .filter((it) => {
                                    if (!searchWord) return it;

                                    const matcher = new RegExp(searchWord, 'gi');

                                    return it.name.match(matcher) || String(it.globalId) === searchWord;
                                })}
                            columns={projectTableColumns}
                            sortDirections={['ascend', 'descend']}
                        />
                    </>
                )}
            </Spin>
        </div>
    );
};

const buttonWrapper = css`
    display: flex;
    justify-content: space-between;
    margin: 10px 0 22px;
`;

const managementBtnWrapper = css`
    display: flex;
    > button {
        margin-left: 10px;
    }
`;

const searchWrapper = css`
    ${Common.searchWrapper}
    margin-bottom: 1rem;
    display: flex;
    justify-content: end;
`;

const inputWrapper = css`
    display: flex;
    flex-direction: row;
`;

const highlight = css`
    font-weight: 600;
`;

export default ProjectList;
