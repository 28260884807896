import { Table, Button, Modal, TablePaginationConfig, Spin, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { NOTICE_STATE_TEXT, NOTICE_STATE, NOTICE_KEY } from 'constants/constants';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import { useCallback } from 'react';
import { css } from '@emotion/react';
import {
    UrgentNoticeDeployRequest,
    IngameNoticeDeployRequest,
    NormalNoticeDeployRequest,
    UrgentNoticeUndeployRequest,
    IngameNoticeUndeployRequest,
    NormalNoticeUndeployRequest,
    IngameNoticeCancelRequest,
} from '@gamebase-web-ops/notice';
import { UseMutateFunction } from 'react-query';
import { noticeStatusColor } from 'utils/color';

interface INoticeTable {
    data?: any[];
    openStep: (data: any, stepType: 'detail' | 'edit' | 'copy') => void;
    projectId: string;
    pagination: TablePaginationConfig | undefined;
    type: string;
    hasWritePermission: boolean;
    deployMutate: UseMutateFunction<
        unknown,
        unknown,
        UrgentNoticeDeployRequest | IngameNoticeDeployRequest | NormalNoticeDeployRequest,
        unknown
    >;
    undeployMutate: UseMutateFunction<
        unknown,
        unknown,
        UrgentNoticeUndeployRequest | IngameNoticeUndeployRequest | NormalNoticeUndeployRequest,
        unknown
    >;
    cancelMutate?: UseMutateFunction<unknown, unknown, IngameNoticeCancelRequest, unknown>;
    noticeListInvalidateQueries: () => void;
    id?: string;
}

const getPublishDisabled = (state: number, endDay: string) => {
    if (
        [
            NOTICE_STATE.WRITE_REQUEST,
            NOTICE_STATE.WRITE_SUCCESS,
            NOTICE_STATE.REMOVE_REQUEST,
            NOTICE_STATE.RESERVED,
        ].includes(state)
    ) {
        return true;
    }

    const endDate = new Date(endDay);
    const nowDate = new Date();
    if (endDate.valueOf() < nowDate.valueOf()) return true;
    return false;
};

function NoticeTable({
    data,
    openStep,
    projectId,
    pagination,
    type,
    hasWritePermission,
    deployMutate,
    undeployMutate,
    noticeListInvalidateQueries,
    cancelMutate,
    id,
}: INoticeTable) {
    const { messageFormatting } = useFormatMessage();
    const columns: ColumnsType<INotice> = [
        {
            title: messageFormatting('AUTHOR'),
            dataIndex: 'createdBy',
            key: 'createdBy',
            render(_, { createdBy, createdAt }) {
                return (
                    <div>
                        {createdBy}
                        <br />
                        {moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                );
            },
        },
        {
            title: messageFormatting('EDITOR'),
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render(_, { updatedBy, updatedAt }) {
                return (
                    <div>
                        {updatedBy}
                        <br />
                        {moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                );
            },
        },
        {
            title: messageFormatting('TITLE'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: messageFormatting('APP_VERSION'),
            dataIndex: 'versions',
            key: 'versions',
            render(_, { versions }) {
                return versions ? versions.map((it, idx) => <Tag key={idx}>{it}</Tag>) : <></>;
            },
        },
        {
            title: messageFormatting('EXPOSURE_PERIOD'),
            dataIndex: 'endTime',
            key: 'exposureDate',
            render(_, { startTime, endTime }) {
                return (
                    <div>
                        <div>{moment(startTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                        <div>{moment(endTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </div>
                );
            },
        },
        {
            title: '우선언어',
            dataIndex: 'defaultLanguage',
            key: 'defaultLanguage',
            render(_, { defaultLanguage }) {
                return defaultLanguage ? <Tag>{defaultLanguage}</Tag> : '';
            },
        },
        {
            title: messageFormatting('STATUS'),
            dataIndex: 'state',
            key: 'state',
            render: (state: number) => (
                <div css={stateText} style={{ color: noticeStatusColor[state as NOTICE_STATE] }}>
                    {NOTICE_STATE_TEXT[state as NOTICE_STATE]}
                </div>
            ),
        },
        {
            title: messageFormatting('MANAGEMENT'),
            key: 'management',
            render(_, data) {
                // if (!hasWritePermission) return null;
                const { id, state, messages, startTime, endTime, title, defaultLanguage, titles, projectId } = data;
                if (state === NOTICE_STATE.WRITE_REQUEST || state === NOTICE_STATE.REMOVE_REQUEST) {
                    setTimeout(() => {
                        noticeListInvalidateQueries();
                    }, 1000);
                }
                const handleClick = () => {
                    openStep(
                        {
                            ...data,
                            id: id,
                            noticeState: state,
                            messages,
                            startTime,
                            endTime,
                            title,
                            defaultLanguage,
                            titles,
                        },
                        'edit',
                    );
                };
                return (
                    <div css={buttonWrap}>
                        <Button onClick={() => openStep({ ...data }, 'detail')}> {messageFormatting('DETAIL')}</Button>
                        <Button
                            onClick={() => handleClick()}
                            disabled={
                                [NOTICE_STATE.WRITE_SUCCESS, NOTICE_STATE.RESERVED].includes(state) ||
                                !hasWritePermission
                            }
                        >
                            {messageFormatting('EDIT')}
                        </Button>
                        <Button onClick={() => openStep({ ...data }, 'copy')} disabled={!hasWritePermission}>
                            {messageFormatting('COPY')}
                        </Button>
                        <Spin
                            spinning={state === NOTICE_STATE.WRITE_REQUEST}
                            indicator={<LoadingOutlined style={{ fontSize: '1.5em' }} spin />}
                        >
                            {state === NOTICE_STATE.RESERVED ? (
                                <Button
                                    danger
                                    onClick={() => cancelConfirm(id)}
                                    disabled={moment(startTime).isBefore(moment())}
                                >
                                    {messageFormatting('CANCEL_RESERVATION')}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    onClick={() => publishConfirm(id)}
                                    disabled={getPublishDisabled(state, endTime) || !hasWritePermission}
                                >
                                    {messageFormatting('PUBLISH')}
                                </Button>
                            )}
                        </Spin>

                        {/* <Spin
                            spinning={state === NOTICE_STATE.REMOVE_REQUEST}
                            indicator={<LoadingOutlined style={{ fontSize: '1.5em' }} spin />}
                        >
                            <Button
                                type="primary"
                                danger
                                data-type="delete"
                                onClick={() => unpublishNotice(projectId, id)}
                                disabled={state !== NOTICE_STATE.WRITE_SUCCESS && state !== NOTICE_STATE.REMOVE_FAIL}
                            >
                                {messageFormatting('STOP')}
                            </Button>
                        </Spin> */}
                    </div>
                );
            },
        },
    ];

    const unpublishNotice = useCallback(
        (projectId: string, id: string) => {
            Modal.confirm({
                title: messageFormatting('NOTICE_UNPUBLISH_CONFIRM'),
                okText: messageFormatting('UNPUBLISH'),
                cancelText: messageFormatting('CANCEL'),
                onOk: () => {
                    undeployMutate({ projectId, id });
                },
            });
        },
        [messageFormatting, undeployMutate],
    );

    const publishConfirm = (id: string) => {
        Modal.confirm({
            title: (
                <span css={modalConfirmColoe(type)}>
                    {messageFormatting(NOTICE_KEY[type] as 'NOTICE_URGENT' | 'NOTICE_NORMAL' | 'NOTICE_INGAME')} 배포{' '}
                    {type === 'emergency' && '⚠️'}
                </span>
            ),
            content:
                type === 'emergency'
                    ? '배포를 누르면 공지사항만 게임에 노출됩니다. 서버 점검이 필요할 경우 꼭 상태를 점검으로 변경해주세요.'
                    : type === 'ingame'
                    ? messageFormatting('INGAME_NOTICE_CONFIRM_WARNING')
                    : messageFormatting('NOTICE_CONFIRM_WARNING'),
            okText: messageFormatting('PUBLISH'),
            cancelText: messageFormatting('CANCEL'),
            onOk: () => {
                deployMutate({ projectId, id });
            },
        });
    };

    const cancelConfirm = (id: string) => {
        Modal.confirm({
            title: '확인',
            content: '예약을 취소하시겠습니까?',
            okText: messageFormatting('CANCEL_RESERVATION'),
            cancelText: messageFormatting('CANCEL'),
            onOk: () => {
                cancelMutate && cancelMutate({ projectId, id });
            },
        });
    };

    return <Table dataSource={data} columns={columns} pagination={pagination} />;
}

export default NoticeTable;

const stateText = css`
    white-space: nowrap;
`;

const buttonWrap = css`
    display: flex;
    max-width: 230px;
    button {
        margin-right: 5px;
    }
`;

const modalConfirmColoe = (type: string) => css`
    font-size: 24px;
    color: ${type === 'emergency' ? 'red' : 'black'};
`;
