import { css } from '@emotion/react';
import { WebviewNoticeDto } from '@gamebase-web-ops/notice';
import { Button, DatePicker, Form, Input, Tabs, TabsProps } from 'antd';
import { EnterIgnoreForm } from 'components';
import { WebviewNoticeCreate } from 'dto/webviewNotice';
import { useQueries, useScreenLock } from 'hooks';

import useWebviewNoticeEdit from 'hooks/notice/useWebviewNoticeEdit';
import useProjectDetail from 'hooks/project/useProjectDetail';
import moment from 'moment';
import { useCallback } from 'react';
import { notificationPush } from 'utils/notificationUtils';
import WebviewEditor from './WebviewEditor';
import WebviewTitleImage from './WebviewTitleImage';

interface IProp {
    onSubmit: (param: WebviewNoticeCreate) => void;
    noticeWebviewData?: WebviewNoticeDto;
}

export default function WebviewEditorForm({ onSubmit, noticeWebviewData }: IProp) {
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);

    const { withScreenLockCallByMessage } = useScreenLock();
    const {
        setNoticeEditorValues,
        uploadImage,
        uploadTitleImage,
        setTitleImages,
        uploadHTML,
        webviewNoticeCreate,
        setSelectedLang,
        mainImages,
        contents,
    } = useWebviewNoticeEdit(projectId, noticeWebviewData ? JSON.parse(JSON.stringify(noticeWebviewData)) : undefined);

    const submitListener = useCallback(
        async (params: any) => {
            const { startTime, endTime, titles } = params;
            const submitObject = { ...webviewNoticeCreate, titles };

            projectDetail?.languages.map((it) => {
                if (titles?.[it] === '') delete titles?.[it];
            });

            const defaultLang = params.defaultLanguage;

            if (defaultLang) {
                if (!contents?.[defaultLang]) {
                    throw new Error('우선 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
                if (!mainImages?.[defaultLang]) {
                    throw new Error('우선 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
                if (!params.titles?.[defaultLang]) {
                    throw new Error('우선 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
            }

            if (startTime.date) {
                submitObject.startTime = new Date(`${startTime.date}`);
            } else {
                notificationPush({
                    status: 'error',
                    title: '시작 날짜를 입력해주세요.',
                });

                return;
            }

            if (endTime.date) {
                submitObject.endTime = new Date(`${endTime.date}`);
            } else {
                notificationPush({
                    status: 'error',
                    title: '종료 날짜를 입력해주세요.',
                });

                return;
            }

            const titleImageUploadScreenLock = withScreenLockCallByMessage('메인 이미지 업로드 중 입니다.');
            await titleImageUploadScreenLock(uploadTitleImage);

            const htmlUploadScreenLock = withScreenLockCallByMessage('HTML을 업로드 중입니다.');
            await htmlUploadScreenLock(uploadHTML);

            onSubmit({ ...submitObject, defaultLanguage: defaultLang ? defaultLang : null });
        },
        [
            webviewNoticeCreate,
            projectDetail?.languages,
            mainImages,
            withScreenLockCallByMessage,
            uploadTitleImage,
            uploadHTML,
            onSubmit,
            contents,
        ],
    );

    const langItems: TabsProps['items'] = projectDetail?.languages?.map((lang) => ({
        forceRender: true,
        key: lang,
        label: lang,
        children: (
            <>
                <Form.Item label="타이틀" css={inputStyle} labelCol={{ span: 2 }} colon={false}>
                    <Input name={`titles.${lang}`} defaultValue={noticeWebviewData?.titles?.[lang]} css={inputStyle} />
                </Form.Item>
                <Form.Item label="배너" labelCol={{ span: 2 }} colon={false}>
                    <WebviewTitleImage
                        onChange={setTitleImages(lang)}
                        titleImageUrl={noticeWebviewData?.mainImages?.[lang]}
                    />
                </Form.Item>
                <div css={editorWrapper}>
                    <WebviewEditor
                        onImageUpload={(file) =>
                            withScreenLockCallByMessage('이미지 업로드 중입니다.')(uploadImage, file)
                        }
                        onChange={setNoticeEditorValues}
                        lang={lang}
                        defaultValueUrl={noticeWebviewData?.contents?.[lang]?.url}
                    />
                </div>
            </>
        ),
    }));

    return (
        <EnterIgnoreForm onSubmit={submitListener}>
            <div>
                <Form.Item label="우선언어" labelCol={{ span: 2 }} colon={false}>
                    <select name="defaultLanguage" css={selectStyle} defaultValue={noticeWebviewData?.defaultLanguage}>
                        <option key="" value="">
                            없음
                        </option>
                        {projectDetail?.languages?.map((lang) => (
                            <option key={lang} value={lang}>
                                {lang}
                            </option>
                        ))}
                    </select>
                </Form.Item>
            </div>
            <section css={wrapper}>
                <Tabs type="card" onChange={setSelectedLang} css={inputStyle} items={langItems}></Tabs>
            </section>
            <section css={dateRangePickerWrapper}>
                <Form.Item>
                    <section css={dateTimePickerWrapper}>
                        <label>시작: </label>
                        <DatePicker
                            showTime
                            name="startTime.date"
                            defaultValue={
                                noticeWebviewData?.startTime ? moment(noticeWebviewData.startTime) : undefined
                            }
                        />
                    </section>
                    <section css={dateTimePickerWrapper}>
                        <label>종료: </label>
                        <DatePicker
                            showTime
                            name="endTime.date"
                            defaultValue={noticeWebviewData?.endTime ? moment(noticeWebviewData.endTime) : undefined}
                        />
                    </section>
                </Form.Item>
            </section>
            <footer css={formFooter}>
                <Button type="primary" htmlType="submit">
                    등록
                </Button>
            </footer>
        </EnterIgnoreForm>
    );
}

const wrapper = css`
    display: flex;
`;

const formFooter = css`
    margin-top: 16px;
    border-top: 1px solid #efefef;
    padding-top: 8px;
    text-align: right;
`;

const dateTimePickerWrapper = css`
    margin-bottom: 24px;
`;

const dateRangePickerWrapper = css`
    margin-top: 32px;
`;

const editorWrapper = css``;

const inputStyle = css`
    width: 100%;
`;

const selectStyle = css`
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    /* width: 240px; */
`;
